<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="12">
          <b-form-group
            class="font-small-3"
            label="기준 사이트"
            label-for="site-select"
            style="max-width: 500px"
          >
            <b-form-select
              id="site-select"
              v-model="siteSelected"
              :options="authSite"
              class="font-small-3"
              size="sm"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <!-- 수동 등록 경기 조회-->
    <b-card>
      <h4 class="">
        <feather-icon
          icon="BoxIcon"
          style="width: 18px; height: 18px"
        />
        수동 경기 결과
      </h4>
      <div class="m-2">
        <!-- Per Page -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="3"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <b-form-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              size="sm"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>
          <b-col
            cols="6"
            lg="6"
            md="6"
            sm="12"
            class=""
          >
            <b-form-group
              class="font-small-3"
              label="경기일시"
              label-for="period"
              label-cols-md="2"
            >
              <b-input-group
                name="period"
                class="calendar-size font-small-3"
              >
                <b-form-datepicker
                  v-model="periodFrom"
                  class=""
                  boundary="window"
                  locale="ko"
                  placeholder="시작일"
                  aria-controls="example-input"
                  size="sm"
                  :max="periodTo"
                />
                <div class="pl-1 pr-1">
                  ~
                </div>
                <b-form-datepicker
                  v-model="periodTo"
                  class=""
                  boundary="window"
                  locale="ko"
                  placeholder="종료일"
                  aria-controls="example-input"
                  size="sm"
                  :min="periodFrom"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <!-- Search -->
          <b-col md="6">
          </b-col>
          <b-col
            class="right"
            cols="12"
            md="6"
          >
            <b-form-group
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <!-- Table -->
      <b-table
        responsive
        show-empty
        hover
        small
        empty-text="No matching records found"
        :per-page="perPage"
        :items="fetchCustomMatchByAdmin"
        :fields="fields2"
        :filter="filter"
        :filter-included-fields="filterOn"
        style="text-align: center !important; font-size: small"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :current-page="currentPage"
        @filtered="onFiltered"
        @row-clicked="rowClickHandler"
      >
        <template
          #cell(index)="data"
          size="sm"
          style="text-align: center; min-width: 3rem"
        >
          {{ totalRows - (perPage * (currentPage - 1) + data.index) }}
        </template>
        <template #cell(site)="data">
          <div
            size="sm"
            style="text-align: center; min-width: 4rem"
          >
            {{ data.item.site }}
          </div>
        </template>
        <template #cell(gameDate)="data">
          <div
            size="sm"
            style="text-align: center; min-width: 10rem"
          >
            {{ data.item.gameDate }}
          </div>
        </template>
        <template #cell(category)="data">
          <div
            size="sm"
            style="text-align: center; min-width: 8rem"
          >
            {{ data.item.category }}
          </div>
        </template>
        <template #cell(oddsType)="data">
          <div
            size="sm"
            style="text-align: center; min-width: 8rem"
          >
            {{ data.item.oddsType }}
          </div>
        </template>
        <template #cell(leagueName)="data">
          <div
            size="sm"
            class="text-truncate"
            style="text-align: left; max-width: 12rem"
            v-b-tooltip.hover.bottom.v-danger="data.item.leagueName"
          >
            {{ data.item.leagueName }}
          </div>
        </template>
        <template #cell(homeName)="data">
          <div
            size="sm"
            style="text-align: right; min-width: 13rem"
          >
            {{ data.item.homeName }}
            <span style="display: inline-block; min-width: 3rem; text-align: right; color: yellow">
              {{ formatNumber(data.item.oddsHome) }}
            </span>
          </div>
        </template>
        <template #cell(handicap)="data">
          <div
            v-if="data.item.handicap"
            size="sm"
            style="text-align: center; min-width: 3rem; color: yellow"
          >
            {{ data.item.handicap }}
          </div>
          <div
            v-else-if="data.item.oddsDraw"
            size="sm"
            style="text-align: center; min-width: 3rem; color: yellow"
          >
            {{ formatNumber(data.item.oddsDraw) }}
          </div>
          <div
            v-else
            size="sm"
            style="text-align: center; min-width: 3rem"
          >
            VS
          </div>
        </template>
        <template #cell(awayName)="data">
          <div
            size="sm"
            style="text-align: left; min-width: 13rem"
          >
            <span style="display: inline-block; min-width: 3rem; text-align: left; color: yellow">
              {{ formatNumber(data.item.oddsAway) }}
            </span>
            {{ data.item.awayName }}
          </div>
        </template>
        <template #cell(status)="data">
          <div
            v-if="data.item.status === '배팅가능'"
            size="sm"
            style="text-align: center; min-width: 4rem; color: limegreen"
          >
            {{ data.item.status }}
          </div>
          <div
            v-if="data.item.status === '배팅마감'"
            size="sm"
            style="text-align: center; min-width: 4rem; color: red"
          >
            {{ data.item.status }}
          </div>
        </template>
        <template #cell(homeTeamResult)="data">
          <div
            v-if="data.item.homeTeamResult"
            size="sm"
            class="text-success"
            style="text-align: center; min-width: 4rem;"
            v-b-modal.modal-result
            @click="selectRow(data)"
          >
            {{ data.item.homeTeamResult }}
          </div>
          <div v-else>
            <b-button
              ref="submit"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="text-warning"
              size="sm"
              v-b-modal.modal-result
              variant="outline-warning"
              style="min-width: 4rem;"
              type="submit"
              @click="selectRow(data)"
            >
              등록
            </b-button>
          </div>
        </template>
        <template #cell(awayTeamResult)="data">
          <div
            v-if="data.item.awayTeamResult"
            size="sm"
            class="text-success"
            style="text-align: center; min-width: 4rem;"
            v-b-modal.modal-result
            @click="selectRow(data)"
          >
            {{ data.item.awayTeamResult }}
          </div>
          <div v-else>
            <b-button
              ref="submit"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="text-warning"
              size="sm"
              v-b-modal.modal-result
              variant="outline-warning"
              style="min-width: 4rem;"
              type="submit"
              @click="selectRow(data)"
            >
              등록
            </b-button>
          </div>
        </template>
        <template #cell(confirmed)="data">
          <div v-if="data.item.homeTeamResult != null && data.item.awayTeamResult != null && data.item.confirmed === null"
               @click="submit('updateCustomResultConfirm', data.item)"
          >
            <b-button
              size="sm"
              variant="outline-success"
              style="text-align: center; min-width: 6rem;"
            >
              결과적용
            </b-button>
          </div>
          <div v-else-if="data.item.confirmed === 'CONFIRMED'"
               @click="submit('updateCustomResultConfirm', data.item)"
          >
            <b-button
              size="sm"
              variant="outline-secondary"
              style="text-align: center; min-width: 5rem;"
            >
              재적용
            </b-button>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="12"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      id="modal-result"
      ref="my-modal"
      title="수동경기결과"
      centered
      size="lg"
      button-size="sm"
      ok-title="저장"
      class="custom-modal-width"
      @ok="submit('updateCustomResult', updateRow)"
    >
      <div class="text-secondary font-small-2">
        {{ updateRow.oddsId }}
      </div>
      <b-table
        responsive
        show-empty
        hover
        small
        empty-text="No matching records found"
        :per-page="perPage"
        :items="[updateRow]"
        :fields="fields1"
        style="text-align: center !important; font-size: small"
      >
        <template #cell(gameDate)="data">
          <div
            size="sm"
            style="text-align: center; min-width: 10rem"
          >
            {{ data.item.gameDate }}
          </div>
        </template>
        <template #cell(homeName)="data">
          <div
            size="sm"
            style="text-align: right; min-width: 13rem"
          >
            {{ data.item.homeName }}
          </div>
        </template>
        <template #cell(vs)="data">
          <div
            size="sm"
            style="text-align: center; min-width: 3rem"
          >
            VS
          </div>
        </template>
        <template #cell(awayName)="data">
          <div
            size="sm"
            style="text-align: left; min-width: 13rem"
          >
            {{ data.item.awayName }}
          </div>
        </template>
        <template #cell(homeTeamResult)="data">
          <div class="d-flex align-items-center justify-content-center">
            <b-form-input
              class="text-success"
              size="sm"
              placeholder="0"
              v-model="homeResult"
              style="text-align: center; width: 3.5rem;"
              @input="updateHomeTeamResult"
            >
            </b-form-input>
          </div>
        </template>
        <template #cell(awayTeamResult)="data">
          <div class="d-flex align-items-center justify-content-center">
            <b-form-input
              class="text-success"
              size="sm"
              placeholder="0"
              v-model="awayResult"
              style="text-align: center; width: 3.5rem;"
              @input="updateAwayTeamResult"
            >
            </b-form-input>
          </div>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BFormGroup,
  BFormSelect,
  BFormSelectOption,
  BInputGroup,
  BInputGroupAppend,
  BFormDatepicker,
  VBTooltip,
  BModal,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { createNamespacedHelpers } from 'vuex'
const gameStore = createNamespacedHelpers('gameStore')

import {
  FETCH_CUSTOM_MATCH_BY_ADMIN,
} from '@/store/game/action'
import { UPDATE_CUSTOM_MATCH_BY_ADMIN, UPDATE_CUSTOM_RESULT_CONFIRM } from '@/store/game/mutation'

import flatPickr from 'vue-flatpickr-component'

import * as moment from 'moment-timezone'
import Swal from "sweetalert2";
moment().tz('Asia/Seoul')
const fmt1 = 'YYYY-MM-DD HH:mm'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BTable,
    BPagination,
    BFormSelect,
    BFormSelectOption,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    VBTooltip,
    flatPickr,
    BModal,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
    flatPickr,
  },
  props: ['data'],
  data() {
    return {
      homeResult: '',
      awayResult: '',
      userData: JSON.parse(localStorage.getItem('userData')),
      authSite: localStorage.getItem('authSite').split(','),
      siteSelected: localStorage.getItem('authSiteSelected'),
      connectedUsers: [],
      socket: null,
      perPage: 15,
      pageOptions: [15, 30, 50],
      sortBy: '@id',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      currentPage: 1,
      totalRows: 1,
      today: moment(new Date()).add(1, 'hours').format('YYYY-MM-DD HH:mm'),
      selectedRowIndex: null,
      selectedRowField: null,
      periodFrom: '',
      periodTo: '',
      option1: [
        { value: 'Sports-Prematch', text: '프리매치' },
      ],
      option2: [
        { value: 'soccer', text: '축구' },
        { value: 'basketball', text: '농구' },
        { value: 'baseball', text: '야구' },
      ],
      option3: [
        { value: 'Match Winner', text: '승무패' },
        { value: 'Asian Handicap', text: '핸디캡' },
        { value: 'Goals Over/Under', text: '언오버' },
      ],
      fields1: [
        {
          key: 'gameDate', sortable: false, label: '경기일시', thClass: 'col3', tdClass: 'border border-primary',
        },
        {
          key: 'homeName', sortable: false, label: '홈팀', thClass: 'col3', tdClass: 'border border-primary',
        },
        {
          key: 'vs', sortable: false, label: 'VS', thClass: 'col3', tdClass: 'border border-primary',
        },
        {
          key: 'awayName', sortable: false, label: '원정팀', thClass: 'col3', tdClass: 'border border-primary',
        },
        {
          key: 'homeTeamResult', sortable: false, label: '홈 점수', thClass: 'col5', tdClass: 'border border-primary',
        },
        {
          key: 'awayTeamResult', sortable: false, label: '원정 점수', thClass: 'col5', tdClass: 'border border-primary',
        },
      ],
      fields2: [
        {
          key: 'index', sortable: false, label: 'No.', thClass: 'col1',
        },
        {
          key: 'site', sortable: false, label: '사이트', thClass: 'col1',
        },
        {
          key: 'gameDate', sortable: false, label: '경기일시', thClass: 'col3',
        },
        {
          key: 'category', sortable: false, label: '카테고리', thClass: 'col2',
        },
        {
          key: 'sports', sortable: false, label: '구분', thClass: 'col2',
        },
        {
          key: 'oddsType', sortable: false, label: '유형', thClass: 'col4',
        },
        {
          key: 'leagueName', sortable: false, label: '리그', thClass: 'col2',
        },
        {
          key: 'homeName', sortable: false, label: '홈팀', thClass: 'col3', tdClass: 'border border-primary',
        },
        {
          key: 'handicap', sortable: false, label: '무/기준', thClass: 'col5', tdClass: 'border border-primary',
        },
        {
          key: 'awayName', sortable: false, label: '원정팀', thClass: 'col3', tdClass: 'border border-primary',
        },
        {
          key: 'status', sortable: false, label: '상태', thClass: 'col5', tdClass: 'border border-primary',
        },
        {
          key: 'homeTeamResult', sortable: false, label: '홈 점수', thClass: 'col5', tdClass: 'border border-primary',
        },
        {
          key: 'awayTeamResult', sortable: false, label: '원정 점수', thClass: 'col5', tdClass: 'border border-primary',
        },
        {
          key: 'confirmed', sortable: false, label: '결과 적용', thClass: 'col5', tdClass: 'border border-primary',
        },
      ],
      filterByToday: true,
      updateRow: {
        site: '',
        category: '',
        sports: '',
        gameDate: '',
        oddsType: '',
        leagueName: '',
        homeName: '',
        awayName: '',
        oddsId: '',
        status: '',
        homeTeamResult: '',
        awayTeamResult: '',
        confirmed: '',
      },
    }
  },
  computed: {
    ...gameStore.mapGetters({
      fetchCustomMatchByAdmin: 'fetchCustomMatchByAdmin',
    }),
    gameStore() {
      return this.$store.state.gameStore.fetchCustomMatchByAdmin
    },
    // currentRow() {
    //   return this.data.item
    // },
  },
  created() {
    this.init()
  },
  watch: {
    siteSelected: {
      handler(event) {
        this.fetchData()
        localStorage.setItem('authSiteSelected', event)
        this.$store.commit('footerStore/setSelectedSiteIndex', this.authSite.indexOf(event))
      },
      immediate: false,
      deep: false,
    },
    fetchSelected(newVal) {
      // When the selected option changes, fetch the data again with the new value
      this.fetchSelected = newVal
      this.fetchData(newVal)
    },
    periodFrom() {
      this.fetchData()
    },
    periodTo() {
      this.fetchData()
    },
    'updateRow.homeTeamResult': {
      immediate: true,
      handler(newVal) {
        if (!newVal) {
          this.homeResult = ''
        }
      },
    },
    'updateRow.awayTeamResult': {
      immediate: true,
      handler(newVal) {
        if (!newVal) {
          this.awayResult = ''
        }
      },
    },
  },
  mounted() {
    this.fetchData()
  },
  setup() {
  },
  methods: {
    ...gameStore.mapActions({
      $fetchCustomMatchByAdmin: FETCH_CUSTOM_MATCH_BY_ADMIN,
      $updateCustomMatchByAdmin: UPDATE_CUSTOM_MATCH_BY_ADMIN,
      $updateCustomResultConfirm: UPDATE_CUSTOM_RESULT_CONFIRM,
    }),
    init() {
      this.periodFrom = moment(new Date()).startOf('month').format(fmt1)
      this.periodTo = moment(new Date()).endOf('month').format(fmt1)
    },
    updateParam(t, e, v, i) {
      const events = {
        t, e, v, i,
      }
      this.$store.commit('gameStore/updateManyPushParam', events)
    },
    async fetchData() {
      const mySite = this.siteSelected
      await this.$fetchCustomMatchByAdmin({
        site: mySite,
        useYn: 'Y',
        periodFrom: this.periodFrom ? (this.periodFrom).split(' ')[0] : '',
        periodTo: this.periodTo ? (this.periodTo).split(' ')[0] : '',
      })
      this.totalRows = this.fetchCustomMatchByAdmin.length
    },
    async submit(target, rowData) {
      await Swal.fire({
        title: '변경내용을 저장하시겠습니까?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.confirmYes(target, rowData)
          // console.log(result.isConfirmed)
          // console.log(target)
        }
      })
    },
    // confirm Yes
    async confirmYes(target, rowData) {
      try {
        if (target === 'updateCustomResult') {
          const mySite = this.siteSelected
          await this.$updateCustomMatchByAdmin({
            site: mySite,
            oddsId: rowData.oddsId,
            homeTeamResult: rowData.homeTeamResult,
            awayTeamResult: rowData.awayTeamResult,
          })
        }
        if (target === 'updateCustomResultConfirm') {
          console.log('rowData ::', rowData)
          const mySite = this.siteSelected
          await this.$updateCustomResultConfirm({
            site: mySite,
            oddsId: rowData.oddsId,
            homeTeamResult: rowData.homeTeamResult,
            awayTeamResult: rowData.awayTeamResult,
            confirmed: 'CONFIRMED',
          })
        }
      } catch {
        console.error()
      } finally {
        Swal.fire({
          title: '저장 완료',
          icon: 'success',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then(result => {
          this.fetchData()
        })
      }
    },
    onRowSelected(items) {
      this.selected = items
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    rowClickHandler(record) {
    },
    formatNumber(value) {
      if (!value) return '0.00'
      return parseFloat(value).toFixed(2)
    },
    selectRow(data) {
      this.updateRow.site = data.item.site
      this.updateRow.category = data.item.category
      this.updateRow.sports = data.item.sports
      this.updateRow.gameDate = data.item.gameDate
      this.updateRow.oddsType = data.item.oddsType
      this.updateRow.leagueName = data.item.leagueName
      this.updateRow.homeName = data.item.homeName
      this.updateRow.awayName = data.item.awayName
      this.updateRow.oddsId = data.item.oddsId
      this.updateRow.status = data.item.status
      this.updateRow.homeTeamResult = data.item.homeTeamResult
      this.updateRow.awayTeamResult = data.item.awayTeamResult
      this.updateRow.confirmed = data.item.confirmed
    },
    updateHomeTeamResult() {
      this.updateRow.homeTeamResult = `${this.homeResult}`
    },
    updateAwayTeamResult() {
      this.updateRow.awayTeamResult = `${this.awayResult}`
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
